












import CheckboxInput from '@/elements/CheckboxInput.vue'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { RoleRequiresApproval } from '@/views/Programs/Volunteers/ext/roleutils'
import { cloneDeep } from 'lodash'
import { roleById } from '@/lib/support/models/UpwardTypes/TypeLeagueVolunteerRole/data'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'

export default defineComponent({
  name: 'RoleApproval',
  components: { CheckboxInput },
  props: {
    value: { type: Array as PropType<LeagueVolunteerRole[]>, required: true, default: () => [] },
    roleDescriptions: {
      type: Array as PropType<TypeLeagueVolunteerRole[]>,
      required: true,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const internalRoles = ref<LeagueVolunteerRole[]>([])
    watch(
      () => props.value,
      () => (internalRoles.value = cloneDeep(props.value)),
      { immediate: true }
    )
    function trigger_input() {
      ctx.emit('input', internalRoles.value)
    }

    function toggleApproved(role: number) {
      const v = internalRoles.value?.find((x) => role == x.roleID)
      if (v) {
        v.approved = !v.approved
      }
      trigger_input()
    }

    const rolesToApprove = computed(() => {
      return internalRoles.value.filter((x) => RoleRequiresApproval(x.roleID))
    })
    return { rolesToApprove, toggleApproved, roleById }
  },
})
