







































import { computed, defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import { getEmptyLeagueCoach } from '@/lib/support/models/LeagueCoach/data'
import { LeagueCoach } from '@/GeneratedTypes/LeagueCoach'
import lodash, { cloneDeep } from 'lodash'
import UDFPanel from '@/components/UDFPanel.vue'
import { League } from '@/GeneratedTypes/League'
import { Camp } from '@/GeneratedTypes/Camp'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { UDFDefinition } from '@/lib/support/components/UDFPanel/UDFDefinition'
import RoleApproval from '@/views/Programs/Volunteers/vues/RoleApproval.vue'

import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import ordersClient from '@/clients/ordersClient'
import { getEmptyLeagueVolunteer, RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import volunteersClient from '@/clients/volunteersClient'
import CoachPreferencesEdit from '@/views/Programs/Volunteers/vues/CoachPreferencesEdit.vue'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'

import { getEmptyCoachProgram } from '@/lib/support/models/CoachProgram/data'

export default defineComponent({
  components: {
    CoachPreferencesEdit,
    UDFPanel,
    RoleApproval,
  },
  props: {
    // toggle programs here.
    programs: { type: Array as PropType<string[]>, default: () => [], required: true },
    // programs have to come from this league
    currentLeague: {
      type: Object as PropType<League | Camp>,
      default: () => getEmptyLeague(),
      required: true,
    },
    // role list
    roles: { type: Array as PropType<LeagueVolunteerRole[]>, default: () => [], required: true },
    // then minimal contact information for product filtering and BE support of initial templates
    gender: { type: String, required: true, default: () => '' },
    // then minimal contact information for product filtering and BE support of initial templates
    contactID: { type: Number, required: true, default: 0 },

    // initial coach prefs from the volunteer get filtered through here and operated on
    coachPrefs: {
      type: Object as PropType<LeagueCoach>,
      default: () => getEmptyLeagueCoach(),
    },
  },
  setup(props, ctx) {
    //** list of products to get sizes from.
    const productList = ref<ParticipantOrderProductInfo[]>([])
    onMounted(async () => {
      productList.value =
        (await ordersClient.getLeagueOrderItems(props.currentLeague?.upwardLeagueID ?? '')) ?? []
    })

    //** intermediate coach preferences
    const editCoachPrefs = ref<LeagueCoach>(getEmptyLeagueCoach())
    watch(
      () => props.coachPrefs,
      () => (editCoachPrefs.value = lodash.cloneDeep(props.coachPrefs) || getEmptyLeagueCoach())
    )

    const actualPrograms = computed(() =>
      props.programs?.filter(
        (x) => (props.currentLeague.programs ?? []).findIndex((y) => x == y?.typeProgramID) >= 0 ?? false
      )
    )

    //** pulls the template for a role or program change from the BE
    async function getProgramTemplate(program: string, role: RolesEnum) {
      return await volunteersClient.retrieveTemplateWithRole(
        props.currentLeague.upwardLeagueID ?? '',
        program,
        role,
        props.contactID && props.contactID > 0 ? props.contactID : undefined
      )
    }

    //** gets a program from a volunteer
    function getProgramFromList(name: string, list: LeagueVolunteer) {
      return list.coachPreferences?.programs?.find((x) => x.typeProgramID == name)
    }

    const editRoles = ref<LeagueVolunteerRole[]>([])

    const hasCoachRole = computed(
      () => editRoles.value?.findIndex((x) => x.roleID == RolesEnum.COACH) >= 0 ?? false
    )

    /**
     * Remove a role
     */
    async function fixCoachPrefs() {
      const programs = actualPrograms.value
      //illegal state to not have programs and have a coach role
      if (hasCoachRole.value && !programs.length) {
        if (!editCoachPrefs.value.programs?.length) {
          await augmentCoachPrefs([props.currentLeague.programs![0].typeProgramID])
        }
        return
      }
      //zero the
      if (!hasCoachRole.value) {
        editCoachPrefs.value = { ...getEmptyLeagueCoach(), programs: [] }
        return
      }
      editCoachPrefs.value.programs = cloneDeep(
        editCoachPrefs.value.programs?.filter((x) => programs.indexOf(x.typeProgramID) >= 0) ?? []
      )

      await augmentCoachPrefs(programs)
    }

    /**
     * Add a program or coach pref using role
     */
    async function augmentCoachPrefs(programs: string[]) {
      if (!editCoachPrefs.value.programs) {
        editCoachPrefs.value.programs = []
      }

      //load programs in turn
      for (const program of programs) {
        const hasProgram = editCoachPrefs.value?.programs?.find((x) => x.typeProgramID == program)
        const hasProduct = editCoachPrefs.value?.programs?.find((x) => x.typeProgramID == program)?.products
          ?.length
        // add them if they don't exist.

        if (!hasProgram || !hasProduct) {
          const p = getProgramFromList(
            program,
            (await getProgramTemplate(program, RolesEnum.COACH)) ?? getEmptyLeagueVolunteer()
          )

          // I repeat this here due to a concurrency issue on programs during the asynchronous call above.
          const hasProgram = editCoachPrefs.value?.programs?.find((x) => x.typeProgramID == program)
          const hasProduct = editCoachPrefs.value?.programs?.find((x) => x.typeProgramID == program)?.products
            ?.length

          if (p) {
            if (!hasProgram) {
              editCoachPrefs.value.programs?.push(cloneDeep(p ?? getEmptyCoachProgram()))
            } else if (!hasProduct) {
              hasProgram.products = cloneDeep(p.products)
            }
          }
        }
      }
    }

    watch(
      () => props.programs,
      async () => await fixCoachPrefs(),
      { immediate: true }
    )

    watch(
      () => props.roles,
      async () => {
        editRoles.value = cloneDeep(props.roles)
        await fixCoachPrefs()
      },
      { immediate: true }
    )
    watch(
      () => props.coachPrefs,
      () => {
        editCoachPrefs.value = cloneDeep(props.coachPrefs ?? getEmptyLeagueCoach())
      },
      { immediate: true }
    )

    watch(
      () => editCoachPrefs.value,
      () => {
        if (!lodash.isEqual(editCoachPrefs.value, props.coachPrefs)) {
          trigger_changed()
        }
      },
      { deep: true }
    )

    const hasUDFToShow = computed(() => {
      for (let i = 0; i < editRoles.value.length; i++) {
        if (UDFByRole(editRoles.value[i].roleID).length) {
          return true
        }
      }
      return false
    })

    /**
     * Return the UDF definitions for a given role.
     */
    function UDFByRole(role: number): UDFDefinition[] {
      return props.currentLeague?.roles?.find((x) => role == x.id)?.udfDefinitions || []
    }

    function trigger_changed() {
      ctx.emit('input', editCoachPrefs.value, editRoles.value)
    }
    return {
      changed: trigger_changed,
      editRoles,
      editCoachPrefs,
      hasCoachRole,
      UDFByRole,
      hasUDFToShow,
      productList,
    }
  },
})
