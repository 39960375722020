















import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import { getEmptyPlayerUDFs } from '@/lib/support/models/PlayerUDFValue/data'

import TextInput from '@/elements/TextInput.vue'
import { UDFDefinition } from '@/lib/support/components/UDFPanel/UDFDefinition'
import { UDFValue } from '@/lib/support/components/UDFPanel/UDFValue'
import { getUDF, setUDF } from '@/lib/components/UDFPanel/support'
import lodash from 'lodash'

export default defineComponent({
  name: 'UDFPanel',
  components: {
    TextInput,
  },
  props: {
    udfList: { type: Array as PropType<UDFDefinition[]>, default: () => [], required: true },
    value: { type: Array as PropType<UDFValue[]>, default: () => getEmptyPlayerUDFs(), required: true },
  },
  setup(props, ctx) {
    const editUdfs = ref<UDFValue[]>(getEmptyPlayerUDFs())

    function input() {
      ctx.emit('input', editUdfs.value)
    }

    function getUDFValue(id: number) {
      const udf = getUDF(id, editUdfs.value)
      return udf.udfValue
    }

    function updateUDFValue(value: string, name: number) {
      setUDF({ udfid: name, udfValue: value }, editUdfs.value)
      input()
    }

    watch(
      () => props.value,
      () => {
        if (!lodash.isEqual(props.value, editUdfs.value)) {
          editUdfs.value = [...props.value]
        }
      },
      { deep: true, immediate: true }
    )

    return {
      getUDFValue,
      updateUDFValue,
    }
  },
})
