/**
 * These are operations that support a size selector.
 * They were externalized to allow the showing of a label based on size selection criteria being relevant.
 */

import { CoachProduct } from '@/GeneratedTypes/CoachProduct'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { productList2ProductOption } from '@/lib/support/models/ListInfo/ProductColorSizeInfo/data'
import { getEmptyParticipantOrderProductInfo } from '@/lib/support/models/ListInfo/ParticipantOrderProductInfo/data'

/***
 * Hide product selection until gender is selected
 * @param gender gender selected
 * @param incoming gender rule of product
 * @return if the product should be shown.
 */

export function genderRule(gender: string, incoming: string, identity: Identity) {
  if (gender == 'F' && identity == Identity.COACH) {
    return incoming == '' || incoming == 'F' || incoming == 'M'
  } else if (gender == 'F' && identity == Identity.PARTICIPANT) {
    return incoming == '' || incoming == 'F'
  } else if (gender == 'M') {
    return incoming == '' || incoming == 'M'
  } else return false
}

export interface ProductOption {
  id: string
  description: string
}
export interface ProductSelections {
  id: number
  name: string
  doNotOrder: boolean
  options: ProductOption[]
}

export enum Identity {
  COACH,
  PARTICIPANT,
}

/**
 * Not every product gets shown on UI, just show the ones where typeSizeID is selectable (not N/A) and
 * that matches the gender rule.
 * @param p
 * @param itemList
 * @param gender
 */
export const productListToProductSelections = (
  p: CoachProduct[],
  itemList: ParticipantOrderProductInfo[],
  gender: string,
  identity: Identity
) => {
  return p
    .filter((o) => {
      const p = findProduct(itemList, o.productID)
      if (p) {
        return o.typeSizeID != 'N/A' && genderRule(gender, p.intendedForGender ?? '', identity)
      }
      return false
    })
    .map(
      (x) =>
        ({
          id: x.productID,
          name: findProduct(itemList, x.productID).registrationLabel,
          doNotOrder: x.doNotOrder,
          options: [
            //allows "none" if the product has not been ordered.
            ...(x.upwardOrderID && findProduct(itemList, x.productID)?.isRequired
              ? []
              : [{ id: 'NONE', description: 'Please select a size...' }]),
            //otherwise can't unset a size after an order is made since these trigger an exchange on the backend.
            ...productList2ProductOption(findProduct(itemList, x.productID).productColorSizes ?? []),
          ],
        } as ProductSelections)
    )
}

/**
 * Quick way to get a product by ID from the product list
 */
export function findProduct(itemList: ParticipantOrderProductInfo[], x: number) {
  return itemList.find((y) => y.id == x) ?? getEmptyParticipantOrderProductInfo()
}
