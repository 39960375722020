













































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import { CoachGenderGrade } from '@/GeneratedTypes/CoachGenderGrade'

import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import ModalMultiSelect from '@/components/ModalMultiSelect.vue'
import { getEmptyLeagueProgram } from '@/lib/support/models/LeagueProgram/data'
import { EditIcon } from '@/elements/Icons'
import store from '@/store'

function stringToGradeSelector(gender: string, gradeid: string): CoachGenderGrade {
  return { genderToCoach: gender, typeGradeID: gradeid }
}

export default defineComponent({
  name: 'GradesSelectorPanel',
  components: {
    ModalMultiSelect,
    SelectInput,
    EditIcon,
  },
  props: {
    value: { type: Array as PropType<CoachGenderGrade[]>, required: true, default: () => [] },
    program: { type: Object as PropType<LeagueProgram>, required: true, default: getEmptyLeagueProgram() },
    showAges: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const internalValue = ref<CoachGenderGrade[]>([])

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      },
      { immediate: true }
    )

    function saveGirls(list: string[]) {
      internalValue.value = [
        ...boysGradesSelected.value.map((x) => stringToGradeSelector('M', x)),
        ...list.map((x) => stringToGradeSelector('F', x)),
      ]
      input()
    }

    function saveBoys(list: string[]) {
      internalValue.value = [
        ...girlsGradesSelected.value.map((x) => stringToGradeSelector('F', x)),
        ...list.map((x) => stringToGradeSelector('M', x)),
      ]
      input()
    }

    const addBoyGradeOpen = ref(false)
    const addGirlGradeOpen = ref(false)

    function addBoyGrades() {
      addBoyGradeOpen.value = true
    }

    function addGirlGrades() {
      addGirlGradeOpen.value = true
    }

    const girlsGrades = computed(
      () =>
        props.program?.gradesByGender
          ?.filter((x) => x.gender == 'F')
          .map((x) =>
            props.showAges
              ? {
                  id: x.typeGradeID,
                  description: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID)?.ageByCutoff,
                }
              : {
                  id: x.typeGradeID,
                  description: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID)?.description,
                }
          ) || []
    )

    const boysGrades = computed(
      () =>
        props.program?.gradesByGender
          ?.filter((x) => x.gender == 'M')
          .map((x) =>
            props.showAges
              ? {
                  id: x.typeGradeID,
                  description: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID)?.ageByCutoff,
                }
              : {
                  id: x.typeGradeID,
                  description: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID)?.description,
                }
          ) || []
    )

    const girlsGradesSelected = computed(() =>
      internalValue.value.filter((x) => x.genderToCoach == 'F').map((x) => x.typeGradeID)
    )

    const boysGradesSelected = computed(() =>
      internalValue.value.filter((x) => x.genderToCoach == 'M').map((x) => x.typeGradeID)
    )

    const girlsGradesSelectedString = computed(() => {
      const grades = girlsGradesSelected.value
      if (grades.length == 0) {
        return '(none)'
      }
      if (grades.length == 1) {
        return `${
          props.showAges
            ? store.getters.gradeTypes.byUpwardTypeId(grades[0])?.ageByCutoff
            : store.getters.gradeTypes.byUpwardTypeId(grades[0])?.description
        }`
      }
      if (grades.length > 1) {
        return grades
          .map((x) =>
            props.showAges
              ? store.getters.gradeTypes.byUpwardTypeId(x)?.ageByCutoff
              : store.getters.gradeTypes.byUpwardTypeId(x)?.description
          )
          .join(',')
      }
    })

    const boysGradesSelectedString = computed(() => {
      const grades = boysGradesSelected.value
      if (grades.length == 0) {
        return '(none)'
      }
      if (grades.length == 1) {
        return `${
          props.showAges
            ? store.getters.gradeTypes.byUpwardTypeId(grades[0])?.ageByCutoff
            : store.getters.gradeTypes.byUpwardTypeId(grades[0])?.description
        }`
      }
      if (grades.length > 1) {
        return grades
          .map((x) =>
            props.showAges
              ? store.getters.gradeTypes.byUpwardTypeId(x)?.ageByCutoff
              : store.getters.gradeTypes.byUpwardTypeId(x)?.description
          )
          .join(',')
      }
    })

    function input() {
      ctx.emit('input', internalValue.value)
    }

    return {
      boysGrades,
      boysGradesSelected,
      addBoyGradeOpen,
      girlsGrades,
      girlsGradesSelected,
      girlsGradesSelectedString,
      boysGradesSelectedString,
      addGirlGradeOpen,
      addBoyGrades,
      saveBoys,
      addGirlGrades,
      saveGirls,
    }
  },
})
