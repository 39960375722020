import { ProductColorSizeInfo } from '@/GeneratedTypes/ListInfo/ProductColorSizeInfo'
import { cloneDeep, uniqBy } from 'lodash'
import { size2human } from '../ParticipantOrderProductInfo/data'

/**
 * Returns a valid list of sizes for a product option
 * This function will consolidate type variations for a given product.
 * @param list
 */
export function productList2ProductOption(list: ProductColorSizeInfo[]) {
  const v = cloneDeep(list)
  v.sort((a, b) => (a.sizeSortOrder > b.sizeSortOrder ? 1 : -1))
  const intermediate = v.map((y) => ({
    id: y.typeSizeID,
    description: size2human(y.typeSizeID ?? ''),
  }))

  return uniqBy(intermediate, 'id')
}
