







import { defineComponent, PropType } from '@vue/composition-api'
import { RoleElementType } from '@/models/RoleElement/RoleElement'

export default defineComponent({
  name: 'RoleElement',
  props: {
    role: { type: Object as PropType<RoleElementType>, required: true },
    canRemove: { type: Boolean, required: false, default: true },
  },
  setup(props, ctx) {
    function remove() {
      ctx.emit('remove', props.role.id)
    }

    function removeClicked() {
      remove()
    }

    return {
      removeClicked,
    }
  },
})
