import { UDFValue } from '@/lib/support/components/UDFPanel/UDFValue'

/**
 * Finds the udf by id in udfs and replaces the value with the one supplied
 * @param udf  - value to replace
 * @param udfs - list of udfs
 */
export function setUDF(udf: UDFValue, udfs: UDFValue[]) {
  let idx = udfs.findIndex((x) => x.udfid == udf.udfid)
  if (udf.udfValue) {
    if (idx < 0) {
      idx = udfs.length
    }
    udfs[idx] = udf
  } else {
    if (idx >= 0) {
      udfs.splice(idx, 1) //if empty then remove the element
    }
  }
}

/**
 * Returns the UDF associated with the id or a new one if not found.
 * @param udfid id to find
 * @param udfs list of udfs
 */
export function getUDF(udfid: number, udfs: UDFValue[]) {
  const idx = udfs.findIndex((x) => x.udfid == udfid)

  if (idx >= 0) {
    return udfs[idx]
  }
  return { udfid: udfid, udfValue: '' }
}
