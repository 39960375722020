





































import { defineComponent, computed, ref, watch, PropType, onMounted } from '@vue/composition-api'
import { isEqual, cloneDeep } from 'lodash'

import TextInput from '@/elements/TextInput.vue'

import {
  IndividualAddressSeedInfo,
  getEmptyIndividualAddress,
  stringToAddressTypeID,
} from '@/lib/support/models/IndividualAddress/data'
import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'

import PostalCodeLookupObserver from '@/components/PostalCodeLookupObserver.vue'
import addressClient from '@/clients/addressClient'
import { PCLRLookupAdapter } from '@/lib/support/components/PostalCodeLookupObserver/PCLRLookupAdapter'
import { CityAndStateRV } from '@/lib/support/components/PostalCodeLookupObserver/types'

export default defineComponent({
  name: 'AddressRowBlock',
  components: {
    PostalCodeLookupObserver,
    TextInput,
  },
  props: {
    value: { type: Object as PropType<IndividualAddress>, required: true },
    required: { type: Boolean, required: false, default: true },
  },
  setup(props, ctx) {
    const zipLookup = PCLRLookupAdapter(addressClient.postalCodeToAddress)
    const editAddress = ref<IndividualAddress>(getEmptyIndividualAddress(new IndividualAddressSeedInfo()))

    function zipLookupUpdate(rvCityState: CityAndStateRV | null) {
      editAddress.value.subdivision1 = rvCityState?.city ?? ''
      editAddress.value.subdivision2 = rvCityState?.shortState || rvCityState?.state || ''
    }

    const composedAddress = computed<IndividualAddress>(() => {
      return {
        ...editAddress.value,
        // these come as blank from server template -- country should be determined by state.
        typeCountryID: editAddress.value.typeCountryID ? editAddress.value.typeCountryID : 'US',
        // also blank from server template, default to mailing
        typeAddressID: editAddress.value.typeAddressID
          ? editAddress.value.typeAddressID
          : stringToAddressTypeID('mailing'),
      }
    })

    /***
     * Address changes, we tell the parent.
     * @param e
     */
    watch(
      () => editAddress.value,
      () => {
        if (!isEqual(editAddress.value, props.value)) {
          fireOnChange()
        }
      },
      { deep: true }
    )

    /**
     * Send parent the new LeaguePlayer
     */
    function fireOnChange() {
      if (editAddress.value) {
        ctx.emit('input', composedAddress.value)
      }
    }

    // reflect changes passed from parent into the edit structures.
    watch(
      () => props.value,
      () => {
        editAddress.value = cloneDeep(props.value)
      },
      { immediate: true }
    )

    onMounted(() => {
      editAddress.value = cloneDeep(props.value)
    })

    return {
      editAddress,
      zipLookup,
      zipLookupUpdate,
    }
  },
})
