































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import { CoachPracticeAvailability } from '@/GeneratedTypes/CoachPracticeAvailability'
import { days as daysLib } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { League } from '@/GeneratedTypes/League'
import ModalMultiSelect from '@/components/ModalMultiSelect.vue'
import { LeaguePracticeNight } from '@/GeneratedTypes/LeaguePracticeNight'
import { ModalMultiSelectValue } from '@/lib/support/components/ModalMultiSelect/ModalMultiSelectValue'
import { EditIcon } from '@/elements/Icons'
import { cloneDeep } from 'lodash'

function StringToPracticeDay(s: string): CoachPracticeAvailability {
  return {
    typeDayID: s,
    endTime: '23:59:59',
    startTime: '00:00:01',
  }
}

function LPNtoMultiSelectValue(s: LeaguePracticeNight): ModalMultiSelectValue {
  return {
    id: s.typeDayID,
    description: s.typeDayID,
  }
}

export default defineComponent({
  name: 'PracticeDaysPanel',
  components: {
    ModalMultiSelect,
    SelectInput,
    EditIcon,
  },
  props: {
    value: { type: Array as PropType<CoachPracticeAvailability[]>, required: true, default: () => [] },
    league: { type: Object as PropType<League>, required: true },
  },
  setup(props, ctx) {
    const internalValue = ref<CoachPracticeAvailability[]>([])

    function addDays() {
      practiceDaysModalOpen.value = true
    }

    function save(newvals: string[]) {
      internalValue.value = newvals.map((x) => StringToPracticeDay(x))
      input()
    }

    const days = ref(daysLib)
    const practiceDaysModalOpen = ref(false)

    const orderedSelectedDays = computed(() => {
      const withOrderingInfo = selectedDays.value.map((x) => {
        return days.value.find((d) => d.name === x) || null
      })

      withOrderingInfo.sort((a, b) => (a?.ordinal || 0) - (b?.ordinal || 0))
      return withOrderingInfo
    })

    const practiceDays = computed(() => {
      return (
        props.league?.practiceNights?.map((x) => LPNtoMultiSelectValue(x)) ||
        days.value.map((x) => ({ id: x.name, description: x.name }))
      )
    })

    const selectedDays = computed(() => internalValue.value.map((x) => x.typeDayID))

    watch(
      () => props.value,
      () => {
        internalValue.value = cloneDeep(props.value)
      },
      { immediate: true }
    )

    function input() {
      ctx.emit('input', internalValue.value)
    }

    return {
      selectedDays,
      orderedSelectedDays,
      practiceDaysModalOpen,
      practiceDays,
      addDays,
      save,
    }
  },
})
