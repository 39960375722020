import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { sizeTypes } from '@/store/sizeTypes'

export function getEmptyParticipantOrderProductInfo() {
  return {
    allowExchange: false,
    allowManualOrder: false,
    cmsProductDescription: '',
    cmsProductImageUrls: null,
    cmsProductName: '',
    cmsSizingGuideUrl: '',
    cmsUnitDescription: '',
    cost: 0,
    gpItemCategory: '',
    gpProgramAbbreviation: '',
    hasNumber: false,
    id: 0,
    includeInactives: false,
    intendedForGender: '',
    isCoachRatioItem: false,
    isPlayerRatioItem: false,
    isRequired: false,
    isSystemInternal: false,
    maximumExchangeQuantity: 0,
    maximumOrderQuantity: 0,
    oprRegistrationLabel: '',
    parentProductID: 0,
    parentUpwardProductID: '',
    productColorSizes: null,
    registrationLabel: '',
    sortOrder: 0,
    trackInPlayerDatabase: false,
    typeLeagueID: '',
    typeParticipantID: '',
    typeProductGroupID: '',
    typeProductTeamKitModeID: '',
    typeProgramID: '',
    upwardProductID: '',
    isColorBasedOnTeamAssignment: false,
  } as ParticipantOrderProductInfo
}

/**
 * changes a product size to something humans that speak english will find
 * understandable.
 * @param size
 * @return human language version of size
 */
export function size2human(size: string) {
  let retval = '???'
  const upwardSize = sizeTypes.state.items.find((x) => x.abbreviation === size)
  if (upwardSize) {
    retval = upwardSize.description!
  }

  return retval
}
