













import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'

import {
  CityAndStateRV,
  PostalCodeAdapterInterface,
} from '@/lib/support/components/PostalCodeLookupObserver/types'
import { debounce } from 'lodash'
import { noOpAdapter } from '@/lib/support/components/PostalCodeLookupObserver/noOpAdapter'

export default defineComponent({
  name: 'PostalCodeLookupObserver',
  props: {
    lookupAdapter: {
      type: Function as PropType<PostalCodeAdapterInterface>,
      required: false,
      default: noOpAdapter,
    },
    value: { required: true, default: '', type: String },
  },
  setup(props, ctx) {
    const lookupRV = ref<CityAndStateRV | null>(null)
    const debouncedLookup = ref<() => void>(debounce(() => lookup(), 500))

    watch(
      () => props.value,
      () => {
        debouncedLookup.value()
      }
    )

    /**
     * Property will provide the slot vars or empty strings
     * <input value="formValue || zipLookupValue" @change="...">
     * would be the case.
     */
    const addressComponents = computed(
      (): CityAndStateRV => lookupRV.value || { city: '', state: '', shortState: '' }
    )

    /**
     * sets the lookupRV and fires if not null.
     */
    async function lookup() {
      try {
        lookupRV.value = await props.lookupAdapter(props.value)
        if (lookupRV.value || lookupRV.value == null) {
          addressUpdate()
        }
      } catch (e) {
        // an invalid lookup needs to be known about but not raise to the level
        // that a user is alerted
        console.error(e)
      }
    }

    /**
     * fires an update event (not null enforced by check in the emitter)
     */
    function addressUpdate() {
      ctx.emit('address-update', lookupRV.value)
    }

    return {
      addressComponents,
    }
  },
})
