import { PostalCodeLookupResult } from '@/clients/addressClient'

/**
 * Uses a function that returns a PostalCodeLookupResult to do a zip lookup.
 * @param getZip - function that returns a PostalCodeLookupResult
 */
export const PCLRLookupAdapter = (getZip: (s: string) => Promise<PostalCodeLookupResult | null>) => async (
  postalCode: string
) => {
  const x = await getZip(postalCode)
  if (x) {
    return {
      city: x?.cityName,
      state: x.provinceName,
      shortState: x.provinceAbbr,
    }
  }
  return null
}
