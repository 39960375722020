/**
 * Rest Operations primarily for zip lookup
 */

import axios from 'axios'
import { postalCodeLooksValid } from '@/lib/support/utilities/address/postalCodeUtil'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'

const baseUrl = 'api/citystatelookup/'
const baseAddressLookupUrl = 'api/addressverification'
const domain = process.env.ADDRESS_ROOT_API || `https://postallocationapiservices.azurewebsites.net/`

export interface PostalCodeLookupResult {
  postalCode: string
  cityName: string
  provinceName: string
  provinceAbbr: string
  latitude: number
  longitude: number
}

class AddressClientException extends GeneralError {
  name = 'Address Lookup Service Error'
}

export interface AddressLookupResult {
  singleAddressLine: string
  cityName: string
  postalCode: string
  streetName: string
  streetNumber: string
  state: string
}

/***
 * Module which coordinates address services, does not use the endpoint for other upward services.
 */
export default class addressClient {
  /***
   * Returns an address or null on invalid input or zip not found.
   * saves a trip if the zip doesn't pass basic US/CA validation
   * @param postalCode - code to lookup
   * @return {PostalCodeLookupResult} resulting address or null
   * @throws AddressClientException
   */
  static postalCodeToAddress = async (postalCode: string): Promise<PostalCodeLookupResult | null> => {
    try {
      if (postalCodeLooksValid(postalCode)) {
        const x = await axios.get<PostalCodeLookupResult[]>(`${domain}${baseUrl}${postalCode}`)

        //casual exploration suggests an empty array is postal code not found.
        if (Array.isArray(x.data)) {
          if (x.data.length) {
            return x.data[0]
          }
          return null
        }
      }
    } catch (e) {
      throw new AddressClientException('Could not lookup zip.')
    }
    return null
  }

  static addressLookup = async (
    streetAddress: string,
    city: string | null,
    state: string | null,
    postalCode: string | null
  ): Promise<AddressLookupResult[] | null> => {
    try {
      const addressSearch = {
        address: {
          street: streetAddress,
          city: city ?? '',
          state: state ?? '',
          postalCode: postalCode ?? '',
          countryCode: 'US',
        },
      }

      const x = await axios.post(`${domain}${baseAddressLookupUrl}`, addressSearch)

      if (x.data && x.data.result && x.data.result.results) {
        return x.data.result.results.map(
          (x: any) =>
            ({
              singleAddressLine: x.address.freeformAddress,
              cityName: x.address.municipality,
              postalCode: x.address.postalCode,
              streetName: x.address.streetName,
              streetNumber: x.address.streetNumber,
              state: x.address.countrySubdivision,
            } as AddressLookupResult)
        )
      }
      return null
    } catch (e) {
      throw new AddressClientException('Could not address.')
    }
  }
}
