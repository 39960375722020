


































import { computed, defineComponent, PropType, ref, watch, onMounted } from '@vue/composition-api'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { cloneDeep, isEqual } from 'lodash'
import SelectInput from '@/elements/SelectInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { CoachProduct } from '@/GeneratedTypes/CoachProduct'
import {
  productListToProductSelections,
  findProduct,
  genderRule,
  Identity,
  ProductSelections,
} from '@/lib/support/components/SizeSelectorPanel/operations'
import store from '@/store'

export default defineComponent({
  name: 'SizeSelectorPanel',
  components: {
    SelectInput,
    CheckboxInput,
  },
  props: {
    itemList: { type: Array as PropType<ParticipantOrderProductInfo[]>, required: true, default: () => [] },
    value: { type: Array as PropType<CoachProduct[]>, required: true, default: () => [] },
    gender: { type: String, required: true, default: () => '' },
    identity: { type: Number, required: false, default: () => Identity.PARTICIPANT },
  },

  setup(props, ctx) {
    const internalValue = ref<CoachProduct[]>([])

    onMounted(async () => {
      await store.dispatch.sizeTypes.fetchAll(false) //get these cached up
    })

    /**
     * Move our prop into internal mutable state
     */
    watch(
      () => props.value,
      () => {
        if (!isEqual(internalValue.value, props.value)) {
          internalValue.value = cloneDeep(props.value)
        }
      },
      { immediate: true }
    )

    /**
     * The intention here is to clear size on a product that is not appropriate for a gender after the gender
     * has been switched
     */
    watch(
      () => props.gender,
      (new_gender) => {
        const vals = cloneDeep(props.value)

        for (let i = 0; i < vals.length; i++) {
          const p = findProduct(props.itemList, vals[i].productID)
          if (p) {
            if (!genderRule(new_gender, p.intendedForGender ?? '', props.identity) && vals[i].typeSizeID) {
              vals[i].typeSizeID = ''
            }
          }
        }
        if (!isEqual(internalValue.value, vals)) {
          internalValue.value = vals
        }
      }
    )

    /**
     * Trigger an input event if the internal model is updated
     */
    watch(
      () => internalValue.value,
      () => {
        //return only the product that are valid for this participant
        ctx.emit('input', validProducts(internalValue.value))
      },
      { deep: true }
    )

    /**
     * Takes the product id and size and sets it internally, should
     * produce an event with the watcher above.
     * @param id
     * @param size
     */
    function setSizeById(id: number, size: string) {
      const v = internalValue.value.findIndex((x) => x.productID == id)
      if (v >= 0) {
        internalValue.value.splice(v, 1, { ...internalValue.value[v], typeSizeID: size })
      }
    }

    function updateDNO(product: ProductSelections) {
      const v = internalValue.value.findIndex((x) => x.productID == product.id)
      if (v >= 0) {
        internalValue.value.splice(v, 1, { ...internalValue.value[v], doNotOrder: product.doNotOrder })
      }
    }

    function sizeByID(id: number) {
      const prod = props.value.find((x) => x.productID == id)
      return prod?.typeSizeID ?? ''
    }

    /**
     * Filter out any products that are not for the gender of the current participant or coach being edited
     */
    function validProducts(products: CoachProduct[]): CoachProduct[] {
      const x = products.filter((o) => {
        const p = findProduct(props.itemList, o.productID)
        if (p) {
          return genderRule(props.gender, p.intendedForGender ?? '', props.identity)
        }
        return false
      })
      return x
    }

    /**
     * Show products that have size info needed. Build the options above.
     */
    const productsToShow = computed(() => {
      // these products per thomas don't get passed back or shown on the UI, only one size.
      const p = productListToProductSelections(props.value, props.itemList, props.gender, props.identity)
      return p
    })

    return { productsToShow, sizeByID, setSizeById, updateDNO }
  },
})
