























































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { getEmptyLeagueCoach } from '@/lib/support/models/LeagueCoach/data'
import { LeagueCoach } from '@/GeneratedTypes/LeagueCoach'
import GradesSelectorPanel from '@/views/Programs/Volunteers/vues/GradesSelectorPanel.vue'
import UDFPanel from '@/components/UDFPanel.vue'
import PracticeDaysPanel from '@/views/Programs/Volunteers/vues/PracticeDaysPanel.vue'
import { League } from '@/GeneratedTypes/League'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { CoachPracticeAvailability } from '@/GeneratedTypes/CoachPracticeAvailability'
import RoleApproval from '@/views/Programs/Volunteers/vues/RoleApproval.vue'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import SizeSelectorPanel from '@/components/SizeSelectorPanel/SizeSelectorPanel.vue'
import { CoachProgram } from '@/GeneratedTypes/CoachProgram'
import { cloneDeep, upperFirst } from 'lodash'
import { Identity } from '@/lib/support/components/SizeSelectorPanel/operations'
import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

export default defineComponent({
  components: {
    GradesSelectorPanel,
    UDFPanel,
    RoleApproval,
    SizeSelectorPanel,
    PracticeDaysPanel,
  },
  props: {
    value: {
      type: Object as PropType<LeagueCoach>,
      default: () => getEmptyLeagueCoach(),
    },
    roles: { type: Array as PropType<LeagueVolunteerRole[]>, default: () => [], required: true },
    productList: {
      type: Array as PropType<ParticipantOrderProductInfo[]>,
      default: () => [],
      required: true,
    },
    currentLeague: { type: Object as PropType<League>, default: () => getEmptyLeague(), required: true },
    gender: { type: String, required: true, default: () => '' },
  },
  setup(props, ctx) {
    const programData = ref<CoachProgram[]>([])

    const editCoachPrefs = ref(getEmptyLeagueCoach())
    const editPracticeDays = ref<CoachPracticeAvailability[]>([])

    function getProgram(name: string) {
      return (props.currentLeague.programs ?? []).find((x) => x.typeProgramID == name)
    }

    watch(
      () => props.value,
      () => {
        editCoachPrefs.value = cloneDeep(props.value ?? getEmptyLeagueCoach())
        decomposePrefs()
      },
      { immediate: true }
    )

    const builtPrefs = computed(() => ({ ...editCoachPrefs.value, programs: programData.value }))

    function decomposePrefs() {
      editPracticeDays.value = editCoachPrefs.value?.practiceNights ?? []
      programData.value = cloneDeep(editCoachPrefs.value.programs ?? [])
    }

    function changed() {
      ctx.emit('input', builtPrefs.value)
    }
    const titleCase = (s: string) =>
      store.getters.leagueAbstraction.isUpwardSelect
        ? props.currentLeague.selectSportName
        : upperFirst(s.toLocaleLowerCase())
    const identity = computed(() => Identity)
    const isLeague = computed(() => {
      return store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    })

    return {
      titleCase,
      changed,
      editCoachPrefs,
      programData,
      getProgram,
      identity,
      isLeague,
      isByAge: computed(() => store.getters.leagueAbstraction.isByAge),
    }
  },
  get isLeague() {
    const opportunityType = store.getters.leagueAbstraction.programType
    if (opportunityType == UpwardOpportunityTypes.LEAGUE) {
      return true
    }
    return false
  },
})
